<template>
  <div class="welcome_page custom_scrollbar">

    <div class="contacts">
      <div class="contacts_item">
        <div class="icon callIcon"></div>
        <div class="text">+ 7 (800) 555-55-55</div>
      </div>
      <div class="contacts_item">
        <div class="icon emeilIcon"></div>
        <div class="text">info@bingosoft.ru</div>
      </div>
    </div>

    <div class="main">

      <div class="title">
        <div class="title_main">Добро пожаловать</div>
        <div class="text28">Выберите пункт в меню слева, чтобы начать работу</div>
      </div>

      <div class="document">
        <div class="docs_column">
          <div class="doc_item">
            <a href="/homepage/empty_file.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Инструкция по работе в системе</div>
            </a>
          </div>
          <div class="doc_item display_none">
            <a href="/homepage/Pochta/Формирование инвестзаявок.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Инструкция по формированию заявок</div>
            </a>
          </div>
        </div>
        <div class="docs_column display_none">
          <div class="doc_item">
            <a href="/homepage/Pochta/Руководство по формированию корректировки проекта.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Руководство по формированию корректировки проекта</div>
            </a>
          </div>
          <div class="doc_item display_none">
            <a href="/homepage/empty_file.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Руководство по формированию отчетности</div>
            </a>
          </div>
        </div>
      </div>

    </div>

    <div class="footer">
      <div class="logo_frame"></div>
      <div class="footer__name">
        <div class="footer_text text28">Автоматизированная система мониторинга оборудования</div>
        <div class="footer_images">
          <div class="image_2 display_none"></div>
          <div class="stick display_none"></div>
          <div class="image_1"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'WelcomePage',
  mounted () {
    this.$el.classList.add('background_' + (1 + Math.floor(Math.random() * Math.floor(5))))
  }
}
</script>

<style scoped src="./Welcome.css">
</style>
